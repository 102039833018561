<template>
    <ul class="section">
        <li v-for="(item,index) in newsList" :key="index" class="animate__animated animate__fadeInUp wow"  @click="href(item.id)">
            <div class="timeBox">
                <div class="timeDate">{{formatDate1(item.create_time)}}</div>
                <div class="timeMonth"><span>{{formatDate2(item.create_time)}}</span><span class="circle"><i></i></span></div>
            </div>
            <div class="newsLeft">
                <img :src="item.photo" class="animate__animated animate__bounceInLeft wow" />
            </div>
            <div class="newsRight animate__animated animate__bounceInRight wow">
                <span>浏览量：{{item.views}}</span>
                <h2>{{item.title}}</h2>
                <p>{{item.remark}}</p>
                <div class="array">→</div>
            </div>
        </li>
    </ul>
</template>
<style  lang="scss" scoped>
    .section{
        width: 10rem;
        margin: 0 auto;
        padding-top: .5rem;
        li{
            padding: .3rem .3rem .3rem;
            display: flex;
            border-bottom: 1px solid #e2e2e2;
            border-radius: .1rem;
            cursor: pointer;
            overflow: hidden;
            .timeBox{
                width: 1.6rem;
                height: .4rem;
                margin: .5rem 0;
                display: flex;
                font-size: .2rem;
                font-family: 'SYMedium';
                .timeDate{
                    border-right: 1px solid #979797;;
                    line-height: .4rem;
                    padding-right: .1rem;
                    font-size: .4rem;                
                    color: #8E8E8E;
                }
                .timeMonth{         
                    color: #262424;
                    font-size: .16rem;
                    font-family: 'SYNormal';
                    margin-left: .1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .circle{
                        display: block;
                        width: 10px;
                        height: 10px;
                        border: 1px solid #979797;
                        border-radius: 50%;
                        justify-content: start;
                        margin-top: .04rem;
                        i{
                            display: block;
                            width: 4px;
                            height: 4px;
                            background: #979797;
                            border-radius: 50%;
                            margin: 3px;
                        }
                    }
                }
            }
            .newsLeft{
                width: 2rem;
                height: 1.4rem;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    transition: all 1s;
                }
            }
            .newsRight{
                flex: 1;
                margin-left: .2rem;
                display: flex;
                flex-direction: column;
                h2{
                    font-size: .16rem;
                    font-weight: normal;
                    margin-top: .1rem;
                }
                p{
                    font-size: .13rem;
                    line-height: .2rem;
                    height: .4rem;
                    margin: .12rem 0;
                    color: #666;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                span{
                    font-size: .12rem;
                    display: block;
                    color: #999;                   
                    background: #E3E4E4;
                    padding:.03rem .1rem;
                    width: .8rem;
                }
                .array{
                    font-size: .2rem;
                    color: #7E7E7E;
                }
            }
        }
        li:hover{
            box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
            .newsLeft{
                img{
                    transform:scale(1.5);
                }
            }
            
        }
    }
</style>
<script>
import { useRouter } from 'vue-router'
import {onMounted} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
import { formatDate } from '@/assets/utils/utils';
export default {
    props:['newsList'],
    setup(){
        onMounted(()=>{
            var wow = new WOW({
                boxClass: 'wow',    //需要执行动画元素的Class
                animateClass: 'animated',    //animation.css动画的Class
                offset: 50,    //距离可视区域多少开始执行动画
                mobile: true,    //是否在移动设备执行动画
                live: true    //异步加载的内容是否有效
            })
            wow.init()
        })
         let router = useRouter();
        function href(id){
            // console.log(id)
            router.push({
                //传递参数使用params的话，只能使用name指定(在route.js里面声明name)
                path:"/newDetail",
                query:{
                    id:id
                }
            })
        } 
        //时间

       function formatDate1(time) {
            time = time * 1000
            let date = new Date(time)
            return formatDate(date, 'dd')
        }  
        function formatDate2(time) {
            time = time * 1000
            let date = new Date(time)
            return formatDate(date, 'yyyy-MM')
        }
        return{
            href,
            formatDate1,
            formatDate2
        }
    }
}
</script>