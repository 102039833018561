<template>
    <!-- 轮播图 -->
    <Banner :imgArr="state.bannerArr" v-if="state.bannerArr!=''" />
    <div class="section">
        <NewsList :newsList = "state.newsList" />
    </div>
</template>
<style  lang="scss" scoped>
  .section{
    padding: .1rem 0 .2rem;
  }
</style>
<script>
import {onMounted,reactive} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
import Banner from "@/components/banner1.vue"
import NewsList from "./componetns/newsCard.vue"
import { getNewsList,getbannerData} from "../../api/request"
export default {
  components:{
        Banner,
        NewsList
    },
    setup(){
        let state = reactive({
          bannerArr:[],
          newsList:[]
        })
        onMounted(()=>{
            var wow = new WOW({
            boxClass: 'wow',    //需要执行动画元素的Class
            animateClass: 'animated',    //animation.css动画的Class
            offset: 100,    //距离可视区域多少开始执行动画
            mobile: true,    //是否在移动设备执行动画
            live: true    //异步加载的内容是否有效
            })
            wow.init()
            getData()
        })
        function getData(){
          getbannerData({id:40}).then((res)=>{
            if(res.code == 200){
              state.bannerArr = res.data
            }
          })
          getNewsList().then((res) => {
              console.log(res)
              if(res.code == 200){
                state.newsList = res.data.list
              }
          })
        }
        return{
          state,
          getData
        }
    }
}
</script>